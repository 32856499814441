import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resource-grid',{staticClass:"ma-4 justify-center",attrs:{"options":_vm.commissionOptions,"items":_vm.commissions},on:{"onParamsChange":_vm.reloadCollection},scopedSlots:_vm._u([{key:"item.order_number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"small":"","to":{ name: 'single-order', params: { id: item.order_number } }}},[_vm._v(" "+_vm._s(item.order_number)+" ")])]}},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(item.percentage)+"%")])]}},{key:"item.commission_on_shipping",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c(VIcon,[_vm._v(_vm._s(item.commission_on_shipping ? 'check' : 'close'))])],1)]}},{key:"item.commission_on_tax",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c(VIcon,[_vm._v(_vm._s(item.commission_on_tax ? 'check' : 'close'))])],1)]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c(VIcon,[_vm._v(_vm._s(item.approved ? 'check' : 'close'))])],1)]}},{key:"item.amount.amount",fn:function(ref){
var item = ref.item;
return [(item.amount)?_c('span',[_vm._v(_vm._s(_vm.$format.money.withStoreCurrency(item.amount)))]):_vm._e()]}},{key:"item.revoked_amount",fn:function(ref){
var item = ref.item;
return [(item.revoked_amount)?_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.$format.money.withStoreCurrency(item.revoked_amount))+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }