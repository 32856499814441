import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.user.id)?_c('div',[_c('user-require-password-change',{model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('user-generate-password',{model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c(VSlideYTransition,{attrs:{"mode":"out-in"}},[(!_vm.user.generate_password)?_c('user-password',{attrs:{"error-messages":_vm.errors['password']},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}):_vm._e()],1)],1):_c('div',[_c(VExpansionPanels,[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(_vm.$t('Security'))+" ")]),_c(VExpansionPanelContent,[_c('user-password-change-inputs',{key:_vm.user.updated_at,model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c(VDivider,{staticClass:"my-4"}),_c('user-actions-password-actions',{staticClass:"d-flex flex-column flex-gap-16",attrs:{"value":_vm.user}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }