import { VBtn } from 'vuetify/lib/components/VBtn';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRadioGroup,{staticClass:"d-flex flex-row",model:{value:(_vm.associated_customer_ids),callback:function ($$v) {_vm.associated_customer_ids=$$v},expression:"associated_customer_ids"}},_vm._l((_vm.accessList),function(type,index){return _c(VRadio,{key:index,attrs:{"label":type.name,"value":type.value}})}),1),(_vm.canSelectCustomers)?_c('select-multiple-customer',{attrs:{"options":{ showChip: true, grid: _vm.gridOptions, filterId: 'product-customer' }},on:{"input":_vm.onSelect},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"no-uppercase",attrs:{"disabled":!(_vm.canRead && _vm.canUpdate && _vm.canDelete),"text":"","to":{ name: 'customer-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}}],null,false,359622411),model:{value:(_vm.currentCustomers),callback:function ($$v) {_vm.currentCustomers=$$v},expression:"currentCustomers"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }