import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VExpansionPanels,[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('Associated Customers')))])])]),_c(VExpansionPanelContent,[_c('user-associated-customers',{staticClass:"d-flex flex-column flex-gap-16",model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1)],1),(_vm.user.id && _vm.canReadRepresentative)?_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('span',[_vm._v(_vm._s(_vm.$t('Sales representative')))])]),_c(VExpansionPanelContent,[_c('div',{staticClass:"d-flex justify-end"},[(_vm.canCreateRepresentative)?_c(VBtn,{attrs:{"color":"primary","small":"","depressed":"","to":{ name: 'representative-create', params: { admin: _vm.$route.params.id } }}},[_vm._v(" "+_vm._s(_vm.$t('Add commission rate'))+" ")]):_vm._e()],1),_c('user-sales-representative',{attrs:{"id":_vm.value.id}})],1)],1):_vm._e(),_c(VDivider),(_vm.user.id && _vm.canReadCommissions)?_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('span',[_vm._v(_vm._s(_vm.$t('Commissions')))])]),_c(VExpansionPanelContent,[_c('user-commissions',{attrs:{"id":_vm.value.id}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }