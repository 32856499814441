import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resource-grid',{staticClass:"ma-4 justify-center",attrs:{"options":_vm.representativeOptions,"items":_vm.representatives},on:{"onParamsChange":_vm.reloadCollection},scopedSlots:_vm._u([{key:"item.commission_percentage",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(item.commission_percentage)+"%")])]}},{key:"item.customer_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'customer-update', params: { id: item.customer_id } }}},[_vm._v(" "+_vm._s(item.customer_id)+" ")])]}},{key:"item.commission_on_shipping",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c(VIcon,[_vm._v(_vm._s(item.commission_on_shipping ? 'check' : 'close'))])],1)]}},{key:"item.commission_on_tax",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c(VIcon,[_vm._v(_vm._s(item.commission_on_tax ? 'check' : 'close'))])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canUpdate)?_c(VBtn,{staticClass:"mx-5 my-3",attrs:{"depressed":"","fab":"","small":"","to":{ name: 'representative-update', params: { id: item.id, admin: _vm.$route.params.id } }}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("edit")])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }